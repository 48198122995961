import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import Chart from './Components/Chart';
import geojson from './resources/geojson.min.json';
import MunicipalityGraph from './Graph/MunicipalityGraph';
import graphData from './resources/municipalityGraph.json';
import distance from '@turf/distance';

function App() {
  const [sourceMunicipality, setSourceMunicipality] = useState('Urk');
  const [hoveredMunicipalityIndex, setHoveredMunicipalityIndex] = useState<number>();
  const [selectedMunicipalityIndex, setSelectedMunicipalityIndex] = useState<number>();
  const [municipalityGraph, setMunicipalityGraph] = useState<MunicipalityGraph>(new MunicipalityGraph({}));
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender])

  useEffect(() => {
    municipalityGraph.fromJson(graphData);
    setRerender(true);
  }, []);

  useEffect(() => {
    municipalityGraph.constructDistanceMap(sourceMunicipality);
  }, [sourceMunicipality])

  const onHover = (index: number) => {
    setHoveredMunicipalityIndex(index);
  }

  const onClick = (index: number) => {
    setSelectedMunicipalityIndex(index);
  };

  const onAltClick = (index: number) => {
    setSourceMunicipality(geojson[index].name);
  };

  const municipalityInfoCard = useMemo(() => {
    if (selectedMunicipalityIndex === undefined && hoveredMunicipalityIndex === undefined) {
      return <></>;
    }

    const sourceMunicipalityIndex = geojson.findIndex((feature) => feature.name === sourceMunicipality);
    const targetMunicipalityIndex = hoveredMunicipalityIndex || selectedMunicipalityIndex || 0;
    const feature = geojson[targetMunicipalityIndex];
    const municipalityDistance = municipalityGraph.distanceMap[sourceMunicipality][feature.name].distance;
    const cartesianDistance = distance(geojson[sourceMunicipalityIndex].center, geojson[targetMunicipalityIndex].center, { units: 'kilometers' });
    // Get road distance from api data
    // TODO get road distance between all municipalities from API and store in app
    return (
      <div>
        <h3>{ feature.name }</h3>
        <div className="text-start">
          <p className="m-0">De afstand tussen jou en { sourceMunicipality } is:</p>
          <p className="m-0">{ `${municipalityDistance} Gemeente${municipalityDistance > 1 ? 'n' : ''}` }</p>
          <p className="m-0">{ `${cartesianDistance.toFixed(1)} Kilometer hemelsbreed` }</p>
          {/*<p className="m-0">X Kilometer over de autoweg</p>*/}
          {/*TODO center svg's*/}
          {/*<img src={ `/svg/${feature.name}.svg` } />*/}
        </div>
      </div>
    )
  }, [selectedMunicipalityIndex, hoveredMunicipalityIndex])

  return (
    <div className="App">
      <Container fluid className="py-5 px-5 h-100">
        <Row className="mb-3">
          <Col xs={ 12 }>
            <h1>Hoe ver ben ik bij { sourceMunicipality } vandaan?</h1>
            <p>Beweeg je muis over jouw gemeente om te zien hoe ver jij bij { sourceMunicipality } vandaan woont.</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 8 }>
            <Chart
              onHover={ onHover }
              onClick={ onClick }
              onAltClick={ onAltClick }
              onHoverEnd={ () => setHoveredMunicipalityIndex(undefined) }
              distances={ municipalityGraph.distanceMap[sourceMunicipality] }
            />
          </Col>
          <Col xs={ 4 }>
            { (selectedMunicipalityIndex !== undefined || hoveredMunicipalityIndex !== undefined) && municipalityInfoCard }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
