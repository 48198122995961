import geojson from '../resources/geojson.json';

export interface MunicipalityGraphNode {
  name: string;
  neighbours: MunicipalityGraphNode[];
}

interface GraphJsonStructure {
  name: string;
  neighbours: string[];
}

export default class MunicipalityGraph {
  nodes: { [name: string]: MunicipalityGraphNode };
  distanceMap: { [startNode: string]: { [destination: string]: { distance: number; previous?: string } } };

  constructor(nodes: { [name: string]: MunicipalityGraphNode }) {
    this.nodes = nodes;
    this.distanceMap = {};
  }

  toJson() {
    return Object.keys(this.nodes).map((nodeName) => ({
      name: nodeName,
      neighbours: this.nodes[nodeName].neighbours.map((node) => node.name),
    }));
  }

  fromJson(json: GraphJsonStructure[]) {
    // console.time('construct municipality graph');
    this.nodes = {};
    json.forEach((node) => {
      // Get currently clicked municipality
      const sourceMunicipalityName = node.name;
      const sourceMunicipalityNode = this.nodes[sourceMunicipalityName] || { name: sourceMunicipalityName, neighbours: [] };

      node.neighbours.forEach((neighbour) => {
        const destMunicipalityName = neighbour;
        const destMunicipalityNode = this.nodes[destMunicipalityName] || { name: destMunicipalityName, neighbours: [] };

        if (!sourceMunicipalityNode.neighbours.includes(destMunicipalityNode)) sourceMunicipalityNode.neighbours.push(destMunicipalityNode);
        if (!destMunicipalityNode.neighbours.includes(sourceMunicipalityNode)) destMunicipalityNode.neighbours.push(sourceMunicipalityNode);

        this.nodes[destMunicipalityName] = destMunicipalityNode;
      });

      this.nodes[sourceMunicipalityName] = sourceMunicipalityNode;
    })
    // console.timeEnd('construct municipality graph');
  }

  constructFullLookupTable() {
    // console.time('construct lookup table calc');
    Object.keys(this.nodes).forEach((startNode) => {
      this.constructDistanceMap(startNode);
    })
    // console.timeEnd('construct lookup table calc');
  }

  constructDistanceMap(from: string, maxDepth = 100) {
    const startNode = this.nodes[from];

    if (!startNode) return;

    // Breadth-first search from start, just visit all nodes
    if (!this.distanceMap[from]) this.distanceMap[from] = {};
    this.distanceMap[from][from] = { distance: 0, previous: undefined };

    let current = startNode;
    let queue = [current];
    let depth = 0;
    do {
      current = queue[0];
      queue = queue.slice(1);
      current.neighbours.forEach((neighbour) => {
        if (this.distanceMap[from][neighbour.name]) return;
        const record = { distance: this.distanceMap[from][current.name].distance + 1, previous: current.name };
        depth = Math.max(depth, record.distance);
        this.distanceMap[from][neighbour.name] = record;
        queue.push(neighbour);
      });
    } while (queue.length > 0 && depth <= maxDepth)
  }
}
